let loginFunction = {
    init: function (formElement) {

    }
}

$(function () {
    let loginForm = $('#login_form');
    if (loginForm) {
        loginFunction.init(loginForm);
    }
});

// eslint-disable-next-line no-unused-vars...

import config from '@config';
import "./vendor/bootstrap.js"; import "./vendor/fontawesome.js";
import "./pages/global.js"; import "./pages/login.js"; import "./pages/masonry.min.js"; import "./pages/register.js";
import "./pages/masonry.min.js";
import "@styles/theme";
import "@images/favicon.ico";
import "airbnb-browser-shims";

// Your code goes here ...
import 'aos/dist/aos';
import AOS from 'aos';
import "mmenu-js/src/mmenu";
import '@fancyapps/fancybox/dist/jquery.fancybox.min';

jQuery(document).ready(function () {
  AOS.init({
    once: true
  })

  new Mmenu("#mobile_menu", {
    extensions: ["position-bottom", "border-full"],
    searchfield: false,
    counters: false,
  });

  $('[data-fancybox="gallery"]').fancybox({

    slideShow : {
      autoStart : false,
      speed     : 4000
    },

    thumbs : {
      autoStart : true,
      axis      : 'x'
    }
  })

  $('a[href^="#"]').click(function(e) {
    e.preventDefault();
    var id = $(this).attr('href'),
      targetOffset = $(id).offset().top,
      menuHeight = 60;

    $('html, body').animate({
      scrollTop: targetOffset - menuHeight
    }, 500);
  });

    /**
     * Scroll menu
     */
    $(window).scroll(function() {
      var scroll = $(window).scrollTop();

      if (scroll >= 500) {
        $(".header").addClass("header-fixed");
      } else {
        $(".header").removeClass("header-fixed");
      }
    });
});

window.onload = () => {
  // Check if the popup should be shown
  if (!localStorage.getItem('popupShown')) {
    // Show the popup
    $('#register').modal('show');
    // Set a flag in localStorage indicating that the popup has been shown
    localStorage.setItem('popupShown', 'true');
    // Set a timer to reset localStorage after 2 hours
    setTimeout(function() {
      localStorage.removeItem('popupShown');
    }, 2 * 60 * 60 * 1000); // 2 hours in milliseconds
  }
};

